import { sendGTMEvent } from '@next/third-parties/google';

/**
 * When a newsletter subscription has been submitted successfully
 * @param subscriptionType
 */
const trackSubmitTrueEvent = (subscriptionType: string) => {
  sendGTMEvent({
    event: 'click_newsletter_subscribe',
    eventValues: {
      ec: 'newsletter',
      ea: 'click',
      el: `subscribenow_${subscriptionType}`, // eg. "subscribenow_week"
      subscription_type: subscriptionType, // eg. "week"
    },
  });
};

export const NewsletterTracking = {
  trackSubmitTrueEvent,
};
