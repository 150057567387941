import { sendGTMEvent } from '@next/third-parties/google';

const clickPromotionDownloadBtn = (type: 'button' | 'form', name: string): void => {
  sendGTMEvent({
    event: 'promotion_download',
    promotion_name: name,
    promotion_download: type,
  });
};

const viewPromotion = (name: string): void => {
  sendGTMEvent({
    event: 'promotion_view',
    promotion_name: name,
  });
};

const promotionStep = (step: number, answer: string, name: string): void => {
  sendGTMEvent({
    event: 'promotion_step',
    promotion_name: name,
    promotion_step: step,
    promotion_answer: answer,
  });
};

const promotionSubmit = (name: string): void => {
  sendGTMEvent({
    event: 'promotion_submit',
    promotion_name: name,
  });
};

export const PromotionTracking = {
  clickPromotionDownloadBtn,
  viewPromotion,
  promotionStep,
  promotionSubmit,
};
