import { Product } from 'types/Product';
import { sendGTMEvent } from '@next/third-parties/google';

const productPage = (product: Product): void => {
  const productSubCatData = product?.categories?.[0]?.productSubcategoryPage;
  sendGTMEvent({
    customDimensionValues: {
      product_id: product.gtin, // eg. "3545477"
      product_name: product.productTitle, // eg. "Pizza Mozzarella Gluten Free"

      product_category: !productSubCatData?.productCategoryPage
        ? productSubCatData?.productSubcategoryPage?.productCategoryPage?.productCategoryName || ''
        : productSubCatData?.productCategoryPage?.productCategoryName || '', // eg. "Pizza"

      product_subcategory: !productSubCatData?.productCategoryPage
        ? productSubCatData?.productSubcategoryPage?.subcategoryPageName || ''
        : productSubCatData?.subcategoryPageName || '', // eg. "Pizza"

      product_tags: product?.metaKeywords || 'na', // eg. "vegan, gluten free, Oetker classics"
      product_brand: '', // eg. "Dr. Oetker" TODO: create logic to get product brand in future, right now cab ne left blank as per comment in ticket BERDOH-706
      product_filling_quantity: product?.fillingQuantity || '', // eg. "370 gr."
    },
  });
};

/**
 * When a product card is clicked in any component on the category or subcategory pages
 * @param product Product
 */
const clickSeeProduct = (product: any): void => {
  if (typeof window === 'undefined') return;
  sendGTMEvent({
    event: 'click_see_product',
    eventValues: {
      ec: 'product landing page',
      ea: 'click',
      el: `${product?.name || 'product_name'}`,
      product_name: `${product?.name || 'product_name'}`,
      product_category: product?.category || 'category', // eg. "Pizza"
      product_subcategory: product?.subcategory || 'na', // eg. "Ristorante Pizza"
      product_tags: product?.tags || 'tags', // eg. "vegan, gluten free, Oetker classics"
      product_brand: product?.brand || 'brand', // eg. "Dr. Oetker"
      product_filling_quantity: product?.fillingQuantity || 'filterQuantity', // eg. "370 gr."
      filter_category: product?.filterCategory || 'filterCategory', // more than one filter category can be selected, in this case send all the selected ones separated by comma eg. "difficulty, time"
      filter_used: product?.filtersUsed || 'filtersUsed', // more than one filter used can be selected, in this case send all the selected ones separated by comma eg. "easy, some practice required, <30 min"
      sorting_used: product?.sortingUsed || 'sortingUsed', // eg. "time"
    },
  });
};

/**
 * When the 'know a little more' accordion is clicked on the product detail page
 * @param sectionDescription - the accordion item title
 * @param position
 */
const clickProductAccordion = (sectionDescription: string, position: number): void => {
  sendGTMEvent({
    event: 'click_product_accordion',
    eventValues: {
      ec: 'product',
      ea: 'click product detail',
      el: `accordion_${sectionDescription}`,
      product_description_section: sectionDescription,
      section_position: position,
    },
  });
};

/**
 * When a subcategory card is clicked on the product landing page
 * in the CategorySubcategoryWrapper component
 * @param card Product
 */
const clickSeeSubcategoryProduct = (card: any): void => {
  sendGTMEvent({
    event: 'click_see_subcategory_product',
    eventValues: {
      ec: 'product landing page',
      ea: 'click',
      el: `${card?.subcategory || 'na'}`,
      product_category: card?.category || 'category', // eg. "Pizza"
      product_subcategory: card?.subcategory || 'na', // eg. "Ristorante Pizza"
    },
  });
};

/**
 * When a subcategory card is clicked on the product landing page
 * in the CategorySubcategoryWrapper component
 * @param card Product
 */
const clickSeeCategoryProduct = (card: any): void => {
  sendGTMEvent({
    event: 'click_see_category_product',
    eventValues: {
      ec: 'product landing page',
      ea: 'click',
      el: `${card?.category || 'na'}`,
      target_product_category: card?.category || 'na', // eg. "Pizza"
    },
  });
};

// ---- PRODUCT CARDS ----
/**
 * Product teaser card clicked tracker in:
 * Subbrand component of product category landing page; or
 * Card carousel in product details page; or
 * Authorable content card
 * @param targetTitle target title the card has available in it's data object
 * @param targetPosition target card position in the list
 * @param containerTitle container component title
 * @param containerPosition container component position, within array of similar components
 */
const productTeaserCardClick = (
  targetTitle: string,
  targetPosition: number,
  containerTitle: string,
  containerPosition: number,
  component: string,
): void => {
  sendGTMEvent({
    event: 'click_teaser_product',
    eventValues: {
      ec: 'teaser product',
      ea: 'click',
      el: `${component}_${targetTitle}`,
      container_title: containerTitle,
      container_position: containerPosition,
      container_type: component,
      target_name: targetTitle,
      target_position: targetPosition,
    },
  });
};

/*
 * When the user clicks on on Buy Now button in the product hero banner
 */
const clickBuyNow = (link: string) => {
  sendGTMEvent({
    event: 'click_online_shop',
    eventValues: {
      ec: 'product',
      ea: 'click',
      el: 'oetker shop',
      external_link: link, //  <Shop link without params or hashes>  eg. "https://www.oetker-shop.de/vitalis-knusperplus-double-chocolate-450g/"
    },
  });
};

/**
 * When the user scrolls 25% of the product detail page and 10 seconds have passed
 */
const productPageTimeScrollPercentage = (): void => {
  sendGTMEvent({
    event: 'timer_product_10s_25p',
  });
};

export const ProductsTracking = {
  clickProductAccordion,
  clickSeeCategoryProduct,
  clickSeeSubcategoryProduct,
  productTeaserCardClick,
  productPage,
  clickBuyNow,
  clickSeeProduct,
  productPageTimeScrollPercentage,
};
