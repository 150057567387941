import { sendGTMEvent } from '@next/third-parties/google';
/*
 * When the user clicks on on a primary navigation link
 */
const primaryLink = (mainNavItem: string) => {
  sendGTMEvent({
    event: 'click_navigation_primary',
    eventValues: {
      ec: 'navigation',
      ea: 'click primary',
      el: mainNavItem,
      navigation_primary: mainNavItem,
    },
  });
};

/*
 * When the user clicks on on a secondary navigation link
 */
const secondaryLink = (mainNavItem: string, secondaryNavItem: string) => {
  sendGTMEvent({
    event: 'click_navigation_secondary',
    eventValues: {
      ec: 'navigation',
      ea: 'click secondary',
      el: `${mainNavItem}_${secondaryNavItem}`,
      navigation_primary: mainNavItem,
      navigation_secondary: secondaryNavItem,
    },
  });
};

/*
 * When the user clicks on on a tertiary navigation link
 */
const tertiaryLink = (mainNavItem: string, secondaryNavItem: string, tertiaryNavItem: string) => {
  sendGTMEvent({
    event: 'click_navigation_tertiary',
    eventValues: {
      ec: 'navigation',
      ea: 'click tertiary',
      el: `${mainNavItem}_${secondaryNavItem}_${tertiaryNavItem}`,
      navigation_primary: mainNavItem,
      navigation_secondary: secondaryNavItem,
      navigation_tertiary: tertiaryNavItem,
    },
  });
};

/**
 *
 * @param target - this will be the displayName of the anchor link
 */
const anchorNavigation = (target: string): void => {
  sendGTMEvent({
    event: 'click_navigation_anchor',
    eventValues: {
      ec: 'navigation',
      ea: 'click anchor',
      el: target,
      target_name: target,
    },
  });
};

/*
 * When the user clicks on CTA of Hero Banner Component on Home Page
 */
const homePageHeaderCTA = () => {
  sendGTMEvent({
    event: 'click_homepage_header',
    eventValues: {
      ec: 'header',
      ea: 'click',
      el: 'homepage-header',
    },
  });
};

export const LinkTracking = {
  tertiaryLink,
  secondaryLink,
  primaryLink,
  anchorNavigation,
  homePageHeaderCTA,
};
