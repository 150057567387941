import { sendGTMEvent } from '@next/third-parties/google';

const getFilterCategoriesCommaSeparatedList = (data: any) => {
  const selectedItems: string[] = [];
  data.map((item: any) => selectedItems.push(item.label));
  return selectedItems.join(', ');
};

const getFilterOptionsCommaSeparatedList = (data: any) => {
  const selectedOptions: string[] = [];
  data.map((item: any) => {
    selectedOptions.push(
      item.options?.filter((opt: any) => opt?.selected === true).map((opt: any) => opt?.label),
    );
  });
  return selectedOptions.join(', ');
};

/*
 * When the user clicks on on Show All results button
 */
const clickSearchAllSuggestions = (searchBoxRef: any, hitsNumber: number) => {
  const searchTerm = searchBoxRef.current?.value;
  sendGTMEvent({
    event: 'click_search_allsuggestions',
    eventValues: {
      ec: 'search',
      ea: 'click search all suggestions',
      el: `${searchTerm}_all`,
      search_term: searchTerm,
      search_suggestion_number: hitsNumber <= 0 ? 0 : hitsNumber,
    },
  });
};

/**
 * Search submit tracker
 * @param searchValue current search term
 * @param hitsNumber number of hits matching the search term
 */
const searchSubmit = (searchValue: string, hitsNumber: number): void => {
  sendGTMEvent({
    event: 'click_search_enter',
    eventValues: {
      ec: 'search',
      ea: 'press enter',
      el: searchValue || '', // eg. "Chocolate"
      search_term: searchValue || '', // eg. "Chocolate"
      search_suggestion_number: hitsNumber <= 0 ? 0 : hitsNumber, // eg. "5"
    },
  });
};

/**
 * Tracker for all states changes on the search page
 * @param query current search query
 * @param filterCategory current filter category
 * @param filterValue current filter value
 * @param sorting current sorting mechanism
 */
const searchChange = (
  query: string,
  filterCategory: string,
  filterValue: string,
  sorting: string,
): void => {
  sendGTMEvent({
    event: 'view_search_result',
    eventValues: {
      ec: 'search',
      ea: 'view search result',
      el: query,
      search_term: query,
      filter_category: filterCategory || 'none',
      filter_used: filterValue || 'none',
      sorting_used: sorting,
    },
  });
};

/**
 * Search category change tracker
 * @param currentCategory current search category that the user in on
 * @param targetCategory target search category that the user is redirecting to
 */
const searchCategoryChange = (currentCategory: string, targetCategory: string): void => {
  sendGTMEvent({
    event: 'click_search_result_category',
    eventValues: {
      ec: 'search',
      ea: 'click result category',
      el: currentCategory,
      target_category: targetCategory,
    },
  });
};

/**
 * Search result card clicked tracker
 * @param targetCategory target category the card has available in it's data object
 * @param targetSubcategory target subcategory the card has available in it's data object
 * @param targetName title of the card, available in it's data object
 * @param currentCategory category of current search results displayed
 * @param teaserPosition position of the card if found in a list
 * @param filterCategory current filter category
 * @param filters applied filters values
 * @param sorting current sorting mechanism
 */
const searchResult = (
  targetCategory: string,
  targetSubcategory: string,
  targetName: string,
  currentCategory: string,
  teaserPosition: number,
  filterCategory: string,
  filters: string,
  sorting: string,
): void => {
  sendGTMEvent({
    event: 'click_search_result',
    eventValues: {
      ec: 'search',
      ea: 'click result link',
      el: `${targetCategory}_${targetSubcategory}_${targetName}`,
      teaser_position: teaserPosition, // card position in results
      current_category: currentCategory, // tab in the search results i.e. Recipes | Products etc
      target_name: targetName, // title of the card
      target_category: targetCategory, // category of the card coming from it's data object
      target_subcategory: targetSubcategory, // subCategory of card coming from it's data object
      filter_category: filterCategory, // filter category currently in use
      filter_used: filters, // filter within that category in use
      sorting_used: sorting, // the sorting option in use
    },
  });
};

/*
 * When the user clicks on on popular search option
 */
const clickPopularSearch = (item: any, index: number) => {
  const category = item?.category || '';
  const selectedIndex = index + 1;
  const searchSuggestion = item?.query || '';

  sendGTMEvent({
    event: 'click_popular_search',
    eventValues: {
      ec: 'search',
      ea: 'click popular search',
      el: `${category}_${searchSuggestion}`, // eg. "recipe_cake_Chocolate Victoria Sponge cake"
      search_suggestion: searchSuggestion, // eg. "Chocolate Victoria Sponge cake"
      search_suggestion_category: category, // eg. "recipe"
      search_suggestion_position: selectedIndex, // eg. "2"
    },
  });
};

/*
 * When the search box is reset
 */
const trackResetSearchBoxEvent = (searchBoxData: string) => {
  sendGTMEvent({
    event: 'click_search_try_newsearch',
    eventValues: {
      ec: 'search',
      ea: 'click no result',
      el: searchBoxData,
      search_term: searchBoxData,
    },
  });
};

/*
 * When no results are returned from a search query
 */
const trackNoResultsEvent = (searchBoxData: string) => {
  sendGTMEvent({
    event: 'click_search_noresult',
    eventValues: {
      ec: 'search',
      ea: 'click no result',
      el: searchBoxData,
      search_term: searchBoxData,
    },
  });
};

/*
 * When the user clicks on on search suggestion
 */
const clickSuggestion = (item: any, index: number, searchBoxRef: any, hitsNumber: number) => {
  const category = item.querySelector('p')?.innerText.split(' / ')?.[0] || '';
  const subCategory = item.querySelector('p')?.innerText.split(' / ')?.[1] || '';
  const selectedIndex = index + 1;
  const searchSuggestion = item.querySelector('h2')?.innerText || '';

  sendGTMEvent({
    event: 'click_search_suggestion',
    eventValues: {
      ec: 'search',
      ea: 'click search suggestion',
      el: `${category}_${subCategory}_${searchSuggestion}`, // eg. "recipe_cake_Chocolate Victoria Sponge cake"
      search_suggestion: searchSuggestion, // eg. "Chocolate Victoria Sponge cake"
      search_term: searchBoxRef.current?.value, // eg. "Chocolat"
      search_suggestion_category: category, // eg. "recipe"
      search_suggestion_subcategory: subCategory, // eg. "cake"
      search_suggestion_position: selectedIndex, // eg. "2"
      search_suggestion_number: hitsNumber <= 0 ? 0 : hitsNumber, // eg. "5"
    },
  });
};

export const SearchTracking = {
  clickPopularSearch,
  clickSearchAllSuggestions,
  trackResetSearchBoxEvent,
  searchSubmit,
  searchChange,
  searchCategoryChange,
  searchResult,
  getFilterCategoriesCommaSeparatedList,
  getFilterOptionsCommaSeparatedList,
  trackNoResultsEvent,
  clickSuggestion,
};
