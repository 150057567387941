import { sendGTMEvent } from '@next/third-parties/google';

/**
 *
 * @param socialPlatform
 */
const followUsClick = (socialPlatform: string): void => {
  sendGTMEvent({
    event: 'click_social_follow',
    eventValues: {
      ec: 'follow us',
      ea: 'click',
      el: socialPlatform,
      social_platform: socialPlatform,
    },
  });
};

/*
 * When the user clicks on on social share button
 */
const clickSocialShare = (
  eventName: string,
  eventEC: string,
  socialPlatform: string,
  sharedItemName: string,
) => {
  sendGTMEvent({
    event: eventName,
    eventValues: {
      ec: eventEC,
      ea: `click ${socialPlatform} share`,
      el: sharedItemName,
      social_platform: socialPlatform,
    },
  });
};

export const SocialMediaTracking = {
  followUsClick,
  clickSocialShare,
};
