import { sendGTMEvent } from '@next/third-parties/google';

/**
 *
 * @param target - this will be the social name the link
 */
const footerFollowUs = (target: string): void => {
  sendGTMEvent({
    event: 'click_footer_followus',
    eventValues: {
      ec: 'footer',
      ea: 'click',
      el: `footer_${target}`,
      social_platform: target,
    },
  });
};

/**
 *
 * @param target - this will be the about name the link
 */
const footerAbout = (target: string): void => {
  sendGTMEvent({
    event: 'click_footer_about',
    eventValues: {
      ec: 'footer',
      ea: 'click',
      el: `${target}`,
      about_option: target,
    },
  });
};

/**
 *
 * @param device - this will be the device name: android or ios
 */
const footerAppStore = (device: string): void => {
  sendGTMEvent({
    event: 'click_footer_appinstall',
    eventValues: {
      ec: 'footer',
      ea: 'click app store',
      el: device,
      app_device: device,
    },
  });
};

/**
 *
 * @param target - this will be the More name the link
 */
const footerMore = (target: string): void => {
  sendGTMEvent({
    event: 'click_footer_more',
    eventValues: {
      ec: 'footer',
      ea: 'click',
      el: `${target}`,
      more_option: target,
    },
  });
};

export const FooterTracking = {
  footerMore,
  footerAppStore,
  footerAbout,
  footerFollowUs,
};
