import { sendGTMEvent } from '@next/third-parties/google';

/**
 * Default page views tracker
 * @param url current page url
 */
const pageview = (url: string): void => {
  if (typeof window === 'undefined') return;
  sendGTMEvent({
    event: 'pageview',
    page: url,
  });
};

/**
 * Default page values tracker
 * @param country current user country
 * @param language current user language
 * @param url current page url
 */
const pageValues = (country: string, language: string, url: string): void => {
  if (typeof window === 'undefined') return;
  window?.dataLayer?.unshift({
    pageValues: {
      country,
      language,
      globalPath: url,
    },
  });
};

const trackEvent = (data: Record<string, string>) => {
  sendGTMEvent(data);
};

/**
 * When the user clicks on one of the category occasion cards on a occasions main page
 * @param occasionCategory
 */
const clickCategoryOccasion = (occasionCategory: string): void => {
  sendGTMEvent({
    event: 'click_see_category_occasion',
    eventValues: {
      ec: 'occasion landing page',
      ea: 'click',
      el: occasionCategory || 'occasion_category', // eg. "birthday"
      occasion_category: occasionCategory || 'occasionCategory', // eg. "birthday"
    },
  });
};

/**
 * Default content groups tracking
 * @param obj any params that needs to be add to the dataLayer
 */
const contentGroups = (obj: any): void => {
  if (typeof window === 'undefined') return;
  sendGTMEvent({
    contentGroups: obj,
  });
};

export const BasicTracking = {
  trackEvent,
  clickCategoryOccasion,
  pageValues,
  pageview,
  contentGroups,
};
