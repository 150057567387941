const createTrackingMarker = (topValue: string, id?: string): HTMLDivElement => {
  const marker = document.createElement('div');
  marker.style.position = 'absolute';
  marker.style.top = topValue;
  marker.style.left = '0';
  marker.style.width = '1px';
  marker.style.height = '1px';
  marker.style.pointerEvents = 'none';
  marker.style.visibility = 'hidden';
  marker.style.zIndex = '-1000';

  if (id) {
    marker.id = id;
  }

  document.body.appendChild(marker);
  return marker;
};

export const TrackingMarker = {
  createTrackingMarker,
};
